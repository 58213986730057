import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';

import { useEffect } from 'react';
import Routes from './routes/Routes';
import store from './redux/store';
import muiTheme from './utils/muiTheme';
import { getAuthTokenFromLocalStorage } from './utils/localStorageHelper';
import ROUTE_CONSTANTS from './utils/constants';
import FCM from './components/firebase messaging/FCM';
import EZY_ROUTES from './routes/ezyRoutes';

const isAuthenticatedRoute = (path) => !!EZY_ROUTES.find((obj) => obj.path === path).authenticated;

function App() {
  useEffect(() => {
    window.addEventListener('storage', () => {
      const isToken = getAuthTokenFromLocalStorage();
      if (!isToken && isAuthenticatedRoute(window.location.pathname)) {
        window.location.replace(ROUTE_CONSTANTS.LOGIN);
      }
    });

    return () => {
      window.removeEventListener('storage', () => {});
    };
  }, []);

  return (
    <Provider store={store}>
      <FCM>
        <ThemeProvider theme={muiTheme}>
          <Routes />
        </ThemeProvider>
      </FCM>
    </Provider>
  );
}

export default App;
